import { UI_TOUR_IDS as CARGO_UI_TOUR_IDS } from '@/products/basis/pages/discover/constants';
import { UI_TOUR_IDS as CARGO_FFA_UI_TOUR_IDS } from '@/products/basis/pages/ffa/discover/constants';
import { UI_TOUR_IDS as FREIGHT_UI_TOUR_IDS } from '@/products/freight/pages/discover/constants';
import { UI_TOUR_IDS as FREIGHT_FFA_UI_TOUR_IDS } from '@/products/freight/pages/ffa/discover/constants';
import { features, permissions } from '@/shared/misc/constants';

import { CONTRACTS, TOOLS } from './constants';

export type MenuConfigSectionItem = {
  /**
   * for  Physical, Financial, Historical etc
   */
  title: string;
  /**
   * optional because it might have children items like Physical, Financial, Netback
   */
  to?: string;
  icon: string;
  /**
   * optional because it might have children items like Physical, Financial, Netback
   */
  feature?: string;
  permission: string;
  compareUsing: 'includes' | 'startsWith';
  subTitle?: string;
  uiTourId?: string;
  /**
   * for children like Spark30, Spark25, etc
   */
  items?: {
    title: string;
    to: string;
    feature: string;
  }[];
};

export type MenuConfigSection = {
  title: string; // Contracts, Tools, etc
  items: MenuConfigSectionItem[];
};

export type MenuConfig = Record<
  'freight' | 'cargo' | 'access' | 'leba',
  MenuConfigSection[]
>;

export const config: MenuConfig = {
  freight: [
    {
      title: CONTRACTS,
      items: [
        {
          title: 'Physical',
          icon: 'pricediscovery',
          uiTourId: FREIGHT_UI_TOUR_IDS.CONTRACTS,
          permission: permissions.ACCESS_LATEST_PRICES_FORWARD,
          compareUsing: 'includes',
          items: [
            {
              title: 'Spark30',
              to: '/freight/spark30',
              feature: features.FREIGHT_PHYSICAL_SPARK30,
            },
            {
              title: 'Spark25',
              to: '/freight/spark25',
              feature: features.FREIGHT_PHYSICAL_SPARK25,
            },
          ],
        },
        {
          title: 'Financial',
          icon: 'pricediscovery',
          uiTourId: FREIGHT_FFA_UI_TOUR_IDS.CONTRACTS,
          permission: permissions.FFA_ACCESS_LATEST_PRICES,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'Spark30',
              to: '/freight/ffa/spark30ffa',
              feature: features.FREIGHT_FFA_SPARK30,
            },
            {
              title: 'Spark25',
              to: '/freight/ffa/spark25ffa',
              feature: features.FREIGHT_FFA_SPARK25,
            },
          ],
        },
      ],
    },
    {
      title: TOOLS,
      items: [
        {
          to: '/freight/historical-data/',
          icon: 'history',
          title: 'Historical Data',
          feature: features.FREIGHT_PRICE_EVOLUTION,
          permission: permissions.PREFIX_ACCESS_SIGNALS_PRICE_EVOLUTION,
          compareUsing: 'startsWith',
        },
        {
          to: '/freight/spread/',
          icon: 'spread',
          title: 'Spread Charting',
          feature: features.FREIGHT_SPREAD,
          permission: permissions.ACCESS_PRICE_SPREADS,
          compareUsing: 'includes',
        },
        {
          to: '/freight/round-trip-calculator/',
          icon: 'calculator',
          title: 'Round Trip Calc',
          feature: features.FREIGHT_PRICE_CALCULATOR,
          permission: permissions.ACCESS_PRICE_CALCULATOR,
          compareUsing: 'includes',
        },
        {
          to: '/freight/indexation/',
          icon: 'graphline',
          title: 'Indexation',
          feature: features.FREIGHT_SETTLEMENT_TRACKER,
          permission: permissions.ACCESS_SETTLEMENT_TRACKER_TIME_SERIES,
          compareUsing: 'includes',
        },
        {
          to: '/freight/routes/discover/',
          icon: 'routes',
          title: 'Routes',
          feature: features.FREIGHT_ROUTES_DISCOVER,
          permission: permissions.PREFIX_ACCESS_ROUTES,
          compareUsing: 'startsWith',
        },
        {
          to: '/freight/floating-storage/',
          icon: 'freightAltLine',
          title: 'Floating Storage',
          subTitle: 'BETA',
          feature: features.FREIGHT_FLOATING_STORAGE,
          permission: permissions.ACCESS_FLOATING_STORAGE,
          compareUsing: 'includes',
        },
        {
          to: '/freight/offset-calculator/',
          icon: 'leaf',
          title: 'Neutral',
          feature: features.FREIGHT_OFFSET_CALCULATOR,
          permission: permissions.ACCESS_OFFSET_CALCULATOR,
          compareUsing: 'includes',
        },
        {
          to: '/freight/alert-centre/',
          icon: 'alert',
          title: 'Notifications',
          feature: features.FREIGHT_ALERT_CENTRE,
          permission: '',
          compareUsing: 'startsWith',
        },
      ],
    },
  ],

  cargo: [
    {
      title: CONTRACTS,
      items: [
        {
          title: 'Physical',
          icon: 'pricediscovery',
          uiTourId: CARGO_UI_TOUR_IDS.CONTRACTS,
          permission: '',
          compareUsing: 'startsWith',
          items: [
            {
              title: 'SparkNWE',
              to: '/cargo/sparknwe',
              feature: features.BASIS_PHYSICAL_SPARKNWE,
            },
            {
              title: 'SparkSWE',
              to: '/cargo/sparkswe',
              feature: features.BASIS_PHYSICAL_SPARKSWE,
            },
          ],
        },
        {
          title: 'Financial',
          icon: 'pricediscovery',
          uiTourId: CARGO_FFA_UI_TOUR_IDS.CONTRACTS,
          permission: permissions.FFA_ACCESS_LATEST_PRICES,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'SparkNWE',
              to: '/cargo/ffa/sparknwe-fin',
              feature: features.BASIS_FFA_SPARKNWE,
            },
            {
              title: 'SparkSWE',
              to: '/cargo/ffa/sparkswe-fin',
              feature: features.BASIS_FFA_SPARKSWE,
            },
          ],
        },
        // SparkClose is controlled by logic in menuStore
      ],
    },
    {
      title: TOOLS,
      items: [
        {
          to: '/cargo/historical-data/',
          icon: 'history',
          title: 'Historical Data',
          feature: features.BASIS_PRICE_EVOLUTION,
          permission: permissions.PREFIX_ACCESS_SIGNALS_PRICE_EVOLUTION,
          compareUsing: 'startsWith',
        },
        {
          to: '/cargo/settlement/',
          icon: 'graphline',
          title: 'Settlement Tracker',
          feature: features.BASIS_SETTLEMENT_TRACKER,
          permission: permissions.ACCESS_SETTLEMENT_TRACKER,
          compareUsing: 'includes',
        },
        {
          icon: 'netback',
          title: 'Netbacks',
          permission: permissions.PREFIX_ACCESS_NETBACKS,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'Netbacks',
              to: '/cargo/netbacks',
              feature: features.BASIS_NETBACK,
            },
            {
              title: 'Arb Breakeven',
              to: '/cargo/netbacks/arb-breakeven',
              feature: features.BASIS_NETBACK_ARB_BREAKEVEN,
            },
          ],
        },
        {
          to: '/cargo/netforwards/',
          icon: 'redo',
          title: 'Netforwards',
          subTitle: 'BETA',
          feature: features.BASIS_NETFORWARDS,
          permission: permissions.ACCESS_NETFORWARDS,
          compareUsing: 'startsWith',
        },
        {
          to: '/cargo/alert-centre/',
          icon: 'alert',
          title: 'Notifications',
          feature: features.BASIS_ALERT_CENTRE,
          permission: '',
          compareUsing: 'startsWith',
        },
      ],
    },
  ],

  access: [
    {
      title: TOOLS,
      items: [
        {
          to: '/access/terminal-map/',
          icon: 'access',
          title: 'Terminal Map',
          feature: features.ACCESS_TERMINAL_MAP,
          permission: permissions.ACCESS_PLATFORM_REQUEST_TERMINAL,
          compareUsing: 'startsWith',
        },
        {
          icon: 'horizontal-bar-chart',
          title: 'Regas Costs',
          permission: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'Terminals',
              to: '/access/regas/terminals',
              feature: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_F,
            },
            {
              title: 'Forwards',
              to: '/access/regas/forwards',
              feature: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_FO,
            },
          ],
        },
        {
          icon: 'vertical-bar-chart',
          title: 'DES Hub Netbacks',
          permission: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'Terminals',
              to: '/access/des-hub-netbacks/terminals',
              feature: features.ACCESS_SPARKR_NETBACKS_F,
            },
            {
              title: 'Forwards',
              to: '/access/des-hub-netbacks/forwards',
              feature: features.ACCESS_SPARKR_NETBACKS_FO,
            },
          ],
        },
        {
          icon: 'vertical-bar-chart',
          title: 'FOB Hub Netbacks',
          permission: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
          compareUsing: 'startsWith',
          items: [
            {
              title: 'Terminals',
              to: '/access/fob-netbacks/terminals',
              feature: features.ACCESS_SPARKR_FOB_NETBACKS_F,
            },
            {
              title: 'Forwards',
              to: '/access/fob-netbacks/forwards',
              feature: features.ACCESS_SPARKR_FOB_NETBACKS_FO,
            },
          ],
        },
        {
          to: '/access/changelog',
          icon: 'history',
          title: 'Costs Changelog',
          feature: features.ACCESS_CHANGELOG,
          permission: '',
          compareUsing: 'startsWith',
        },
      ],
    },
  ],
  leba: [
    {
      title: CONTRACTS,
      items: [
        {
          title: 'Discover',
          icon: 'pricediscovery',
          permission: permissions.GAS_LEBA_PLATFORM_READ,
          compareUsing: 'includes',
          items: [
            {
              title: 'SparkLEBA-TTF',
              to: '/gas/leba/ttf',
              feature: features.GAS_LEBA_TTF,
            },
            {
              title: 'SparkLEBA-THE',
              to: '/gas/leba/the',
              feature: features.GAS_LEBA_THE,
            },
          ],
        },
      ],
    },
    {
      title: TOOLS,
      items: [
        {
          to: '/gas/leba/indexation/',
          icon: 'graphline',
          title: 'Indexation',
          feature: features.FREIGHT_SETTLEMENT_TRACKER,
          permission: permissions.ACCESS_SETTLEMENT_TRACKER_TIME_SERIES,
          compareUsing: 'includes',
        },
      ],
    },
  ],
};
